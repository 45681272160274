import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef } from 'react';
import Pagination from 'react-js-pagination';
import Highlighter from 'react-highlight-words';
import * as Styled from '../../styles/searchPageTemplateStyle';
import { getSearchData } from '../../api/searchApi';
import { addTrailingSlash } from '../../utils';

const SearchPageTemplate = ({ location }) => {
  const [translation] = useTranslation();
  const params = new URLSearchParams(location?.search?.slice(1));
  const q = params?.get('q') || '';
  const [query, setQuery] = useState(q);
  const inputEl = useRef(null);
  const itemsPerPage = 5;
  const [activePage, setActivePage] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [results, setResults] = useState([]);
  const [currentElement, setcurrentElement] = useState([]);

  const search = () => {
    getSearchData(q)
      .then(({ data }) => {
        const visitedSlugs = new Set();
        const filteredHit = data?.hits?.hit?.filter((obj) => {
          const Region = obj?.fields?.abbreviation
            ? obj?.fields?.abbreviation
            : '';
          if (
            visitedSlugs?.has(
              obj?.fields?.slug + (Region && 'region=' + Region.toLowerCase())
            )
          ) {
            return false;
          } else {
            visitedSlugs.add(
              obj?.fields?.slug + (Region && 'region=' + Region.toLowerCase())
            );
            return true;
          }
        });
        setResults(filteredHit);
        setcurrentElement(filteredHit?.slice(activePage, itemsPerPage));
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  };

  React.useEffect(() => {
    if (q) {
      setLoading(true);
      setActivePage(0);
      search();
    }
  }, [location]);

  React.useEffect(() => {
    setcurrentElement(results?.slice(activePage, itemsPerPage));
  }, [results]);

  const getDataWithEllipsis = (content, content_length) => {
    const indexOfSearchInContent = content
      ?.toLowerCase()
      ?.indexOf(q?.toLowerCase());

    if (indexOfSearchInContent === -1) {
      return content
        ?.substr(0, content_length)
        ?.concat(content?.length > content_length ? '...' : '');
    }
    if (content.length - (indexOfSearchInContent + q.length) < content_length) {
      return content;
    }
    if (content.length - (indexOfSearchInContent + q.length) > content_length) {
      return indexOfSearchInContent > 3
        ? '...' +
            content?.substr(indexOfSearchInContent, content_length) +
            '...'
        : content?.substr(indexOfSearchInContent, content_length) + '...';
    }
    const data = content?.substr(
      Math.abs(indexOfSearchInContent),
      content_length + indexOfSearchInContent
    );

    return data;
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setcurrentElement(paginate(results, itemsPerPage, pageNumber));
  };

  const paginate = (array, page_size, page_number) => {
    if (!array) {
      return [];
    }
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    // `inputEl.current` points to the mounted search input element
    const q = inputEl.current.value;
    if (q) {
      navigate(`${location?.pathname}?q=${q}`);
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    setQuery(params.get('q') || '');
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{translation('SearchTitle')}</title>
      </Helmet>

      <Styled.SearchPageContainer id="search-title">
        <Styled.SearchInput
          ref={inputEl}
          name="query"
          type="text"
          value={query}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
        <Styled.InputSubmit
          name="submit"
          type="submit"
          value={translation('Go')}
          onClick={handleSubmit}
        />

        {isError ? (
          <Styled.NoResultFound>
            Something went wrong Please try again
          </Styled.NoResultFound>
        ) : q ? (
          !isLoading ? (
            <Styled.SearchResults>
              {currentElement && currentElement?.length > 0 ? (
                currentElement?.map(({ fields }, index) => {
                  return (
                    <Styled.SearchResult key={index}>
                      <a
                        href={
                          process.env.GATSBY_DOMAIN_URL +
                          addTrailingSlash(fields?.slug) +
                          (fields?.abbreviation && fields?.abbreviation !== ''
                            ? '?region=' + fields?.abbreviation?.toLowerCase()
                            : '')
                        }
                      >
                        <h3>
                          <Highlighter
                            highlightClassName="highlightText"
                            searchWords={[q]}
                            autoEscape={true}
                            textToHighlight={fields?.title}
                          />
                        </h3>
                      </a>
                      <p>
                        {fields?.metadata
                          ?.toLowerCase()
                          ?.search(q?.toLowerCase()) >= 0 && (
                          <Highlighter
                            highlightClassName="highlightText"
                            searchWords={[q]}
                            autoEscape={true}
                            textToHighlight={getDataWithEllipsis(
                              fields?.metadata,
                              100
                            )}
                          />
                        )}
                        {fields?.content_subtext
                          ?.toLowerCase()
                          ?.search(q?.toLowerCase()) >= 0 && (
                          <Highlighter
                            highlightClassName="highlightText"
                            searchWords={[q]}
                            autoEscape={true}
                            textToHighlight={getDataWithEllipsis(
                              fields?.content_subtext,
                              100
                            )}
                          />
                        )}
                        {fields?.content_header && (
                          <Highlighter
                            highlightClassName="highlightText"
                            searchWords={[q]}
                            autoEscape={true}
                            textToHighlight={getDataWithEllipsis(
                              fields?.content_header,
                              100
                            )}
                          />
                        )}
                        {fields?.content_raw && (
                          <Highlighter
                            highlightClassName="highlightText"
                            searchWords={[q]}
                            autoEscape={true}
                            textToHighlight={getDataWithEllipsis(
                              fields?.content_raw,
                              250
                            )}
                          />
                        )}
                      </p>
                      <Styled.PageLink
                        href={
                          process.env.GATSBY_DOMAIN_URL +
                          addTrailingSlash(fields?.slug) +
                          (fields?.abbreviation && fields?.abbreviation !== ''
                            ? '?region=' + fields?.abbreviation?.toLowerCase()
                            : '')
                        }
                      >
                        <Highlighter
                          highlightClassName="highlightText"
                          searchWords={[q]}
                          autoEscape={true}
                          textToHighlight={
                            process.env.GATSBY_DOMAIN_URL +
                            addTrailingSlash(fields?.slug) +
                            (fields?.abbreviation && fields?.abbreviation !== ''
                              ? '?region=' + fields?.abbreviation?.toLowerCase()
                              : '')
                          }
                        />
                      </Styled.PageLink>
                      <br />
                    </Styled.SearchResult>
                  );
                })
              ) : (
                <>
                  <Styled.NoResultFound>
                    {translation('NoResultFound')}
                  </Styled.NoResultFound>
                </>
              )}
            </Styled.SearchResults>
          ) : (
            <Styled.NoResultFound>Loading...</Styled.NoResultFound>
          )
        ) : (
          <Styled.NoResultFound>
            Please Enter a keyword to search
          </Styled.NoResultFound>
        )}

        {q && currentElement && currentElement?.length ? (
          <Styled.PaginationDiv>
            <Pagination
              firstPageText=""
              lastPageText=""
              prevPageText="← Previous"
              nextPageText="Next →"
              activePage={activePage ? activePage : 1}
              itemsCountPerPage={5}
              totalItemsCount={results?.length}
              pageRangeDisplayed={5}
              onChange={(e) => {
                handlePageChange(e);
                const scrollID = document?.getElementById('search-title');
                if (scrollID) {
                  scrollID.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              itemClass="itemClass"
              itemClassPrev="first"
              itemClassNext="last"
              disabledClass="disabled"
              linkClass="linkClass"
              activeLinkClass="activeLinkClass"
              linkClassFirst="linkClassFirst"
              linkClassLast="linkClassLast"
            />
          </Styled.PaginationDiv>
        ) : null}
      </Styled.SearchPageContainer>
    </>
  );
};

export default SearchPageTemplate;
